import React from "react";
import { Gizmo, RootGizmo } from "flowy-3-core";
import View from "./root/view/View";
import Form from "./root/form/Form";
import TextField from "./daq/TextField/TextField";
import NumberField from "./daq/NumberField/NumberField";
import SelectField from "./daq/SelectField/SelectField";
import FileField from "./daq/FileField/FileField";
import DateField from "./daq/DateField/DateField";
import PhoneField from "./daq/PhoneField/PhoneField";
import AnnexForm from "./daq/AnnexForm/AnnexForm";
import MultimediaField from "./daq/MultimediaField/MultimediaField";
import SignatureField from "./daq/SignatureField/SignatureField";
import RateField from "./daq/RateField/RateField";
import BooleanField from "./daq/BooleanField/BooleanField";
import IdOcrField from "./daq/IdOcrField/IdOcrFieldNew";
import Text from "./visual/Text/Text";
import Button from "./visual/Button/Button";
import Index from "./visual/Index/Index";
import Chart from "./analytic/Chart/Chart";
import Indicator from "./analytic/Indicator/Indicator";
import Column from "./container/Column/Column";
import Row from "./container/Row/Row";
import Section from "./container/Section/Section";
import Custom from "./custom/Custom/Custom";
import Folio from "./display/Folio/Folio";
import * as S from "./render-gizmos.styles";
import { Col } from "antd";

/**
 * Render component of a Gizmo
 *
 * @param {RootGizmo | Gizmo} gizmo gizmo that's going to be rendered
 * @returns
 */
function renderGizmo(gizmo: RootGizmo | Gizmo): React.ReactNode {
  const type = gizmo.getConfig().type;
  const fid = gizmo.getConfig().fid;
  let columnSizes;

  let gizmoComponent: React.ReactNode = (
    <div key={gizmo.getConfig().fid}>
      Gizmo of type {type} not supported, fid: {gizmo.getConfig().fid}, label:{" "}
      {gizmo.getConfig().label}
    </div>
  );

  if (type !== "view" && type !== "form") {
    const cols = (gizmo as Gizmo).getColumns();
    columnSizes = {
      xs: 24 / cols.s,
      sm: 24 / cols.m,
      md: 24 / cols.l,
      xxl: 24 / cols.xl,
    };
  }

  if (type === "view") {
    gizmoComponent = <View key={fid} gizmo={gizmo as RootGizmo} />;
  } else if (type === "form") {
    gizmoComponent = <Form key={fid} gizmo={gizmo as RootGizmo} />;
  } else if (type === "text_field") {
    gizmoComponent = <TextField key={fid} gizmo={gizmo as Gizmo} />;
  } else if (type === "number_field") {
    gizmoComponent = <NumberField key={fid} gizmo={gizmo as Gizmo} />;
  } else if (type === "select_field") {
    gizmoComponent = <SelectField key={fid} gizmo={gizmo as Gizmo} />;
  } else if (type === "file_field") {
    gizmoComponent = <FileField key={fid} gizmo={gizmo as Gizmo} />;
  } else if (type === "date_field") {
    gizmoComponent = <DateField key={fid} gizmo={gizmo as Gizmo} />;
  } else if (type === "phone_field") {
    gizmoComponent = <PhoneField key={fid} gizmo={gizmo as Gizmo} />;
  } else if (type === "multimedia_field") {
    gizmoComponent = <MultimediaField key={fid} gizmo={gizmo as Gizmo} />;
  } else if (type === "signature_field") {
    gizmoComponent = <SignatureField key={fid} gizmo={gizmo as Gizmo} />;
  } else if (type === "boolean_field") {
    gizmoComponent = <BooleanField key={fid} gizmo={gizmo as Gizmo} />;
  } else if (type === "rate_field") {
    gizmoComponent = <RateField key={fid} gizmo={gizmo as Gizmo} />;
  } else if (type === "id_ocr_field") {
    gizmoComponent = <IdOcrField key={fid} gizmo={gizmo as Gizmo} />;
  } else if (type === "annex_form") {
    gizmoComponent = <AnnexForm key={fid} gizmo={gizmo as Gizmo} />;
  } else if (type === "text") {
    gizmoComponent = <Text key={fid} gizmo={gizmo as Gizmo} />;
  } else if (type === "button") {
    gizmoComponent = <Button key={fid} gizmo={gizmo as Gizmo} />;
  } else if (type === "chart") {
    gizmoComponent = <Chart key={fid} gizmo={gizmo as Gizmo} />;
  } else if (type === "indicator") {
    gizmoComponent = <Indicator key={fid} gizmo={gizmo as Gizmo} />;
  } else if (type === "column") {
    gizmoComponent = <Column key={fid} gizmo={gizmo as Gizmo} />;
  } else if (type === "row") {
    gizmoComponent = <Row key={fid} gizmo={gizmo as Gizmo} />;
  } else if (type === "section") {
    gizmoComponent = <Section key={fid} gizmo={gizmo as Gizmo} />;
  } else if (type === "custom") {
    gizmoComponent = <Custom key={fid} gizmo={gizmo as Gizmo} />;
  } else if (type === "index") {
    gizmoComponent = <Index key={fid} gizmo={gizmo as Gizmo} />;
  } else if (type === "folio") {
    gizmoComponent = <Folio key={fid} gizmo={gizmo as Gizmo} />;
  }

  if (type !== "view" && type !== "form" && type !== "button") {
    gizmoComponent = (
      <Col {...columnSizes} key={fid}>
        {gizmoComponent}
      </Col>
    );
  }

  if (gizmoComponent) {
    return gizmoComponent;
  } else {
    return (
      <div key={gizmo.getConfig().fid}>
        Gizmo of type {type} not supported, fid: {gizmo.getConfig().fid}, label:{" "}
        {gizmo.getConfig().label}
      </div>
    );
  }
}

/**
 * Render a list of gizmos
 *
 * @param {(RootGizmo | Gizmo)[]} gizmos list of gizmos that are going to be rendered
 * @returns
 */
function renderGizmos(gizmos: (RootGizmo | Gizmo)[]): React.ReactNode {
  return (
    <>
      {gizmos.map((gizmo: RootGizmo | Gizmo) => renderGizmo(gizmo))}
    </>
  );
}

export default renderGizmos;
