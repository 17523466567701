import { Row } from "antd";
import { quintanaRooLogoMujerEsVida } from "../src/assets/images";

const Closed = () => {
  return (
    // <div className="closed">
    // <h1>Sorry, we are closed</h1>
    // </div>
    <Row justify={"center"}>
      <img
        src={quintanaRooLogoMujerEsVida}
        style={{
          maxWidth: "100%",
        }}
      />
			<h3>
			El periodo de inscripción ha concluido, te invitamos a estar pendiente de la publicación de resultados en las redes sociales de la Secretaría de Bienestar de Quintana Roo y en <a href="https://qroo.gob.mx/sedeso/mujeresvida/">qroo.gob.mx/sedeso/mujeresvida/</a>.
			</h3>
    </Row>
  );
};

export default Closed;
