import styled from "styled-components";
import InputMask from "react-input-mask";
import { Button } from "antd";

const PhoneField = styled(InputMask)`
  width: calc(100% - 22px);
  padding: 4px 11px;
  line-height: 1.5714285714285714;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
`;

const VerificationField = styled(InputMask)`
  width: calc(100% - 22px);
  padding: 4px 11px;
  line-height: 1.5714285714285714;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
`;

const VerificationButton = styled(Button)`
`;

export { PhoneField, VerificationButton, VerificationField };
