import { Input } from "antd";
import styled from "styled-components";
import { GizmoContainerResize } from "../../utils";

const Index = styled(GizmoContainerResize)`
  // border: 2px dashed blue;
`;

const { Search: SearchAntd } = Input
const Search = styled(SearchAntd)`
  width: 250px;
`;

export { Index, Search };
