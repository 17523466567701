import { FC, useState, useEffect, useRef } from "react";
import { Gizmo, IError, useGizmo } from "flowy-3-core";
import { AutoComplete, Button, Space, Spin, Typography } from "antd";
import { QrcodeOutlined } from "@ant-design/icons";
import { QrReader } from "react-qr-reader";
import clone from "clone";
import { BehaviorSubject, debounceTime } from "rxjs";
import * as S from "./TextField.styles";
import { GizmoWrapper } from "../../utils";
import SearchResultAssociations from "./SearchResultAssociations";

const { Text } = Typography;

type TextFieldProps = {
  gizmo: Gizmo;
};

interface ICodeScanner {
  show: boolean;
  label: string;
}

interface ISearchMessage {
  message: string;
  type: "secondary" | "success" | "warning" | "danger";
}

const TextField: FC<TextFieldProps> = ({ gizmo }) => {
  const { features, binder, errors, config } = useGizmo({ gizmo });
  const [value, setValue] = useState<string>("");
  const [options, setOptions] = useState<{ value: string | any }[]>([]);
  const [loading, setLoading] = useState(false);
  const [isAutoComplete, setIsAutoComplete] = useState(false);
  // When set to true the autocomplete will be open
  const [open, setOpen] = useState(false);
  const [codeScanner, setCodeScanner] = useState<ICodeScanner | undefined>(
    binder.getCodeScanner()
  );
  const [searchMessage, setSearchMessage] = useState<
    ISearchMessage | undefined
  >();
  const [gizmoSearchFeatures, setGizmoSearchFeatures] = useState<
    any | undefined
  >();
  const scannerChange = useRef<BehaviorSubject<any> | undefined>();
  const inputRef = useRef<any>(null);
  const autocompleteRef = useRef<any>(null);

  useEffect(() => {
    if (binder) {
      // console.log("***********config: ", config);
      // console.log("cs: ", binder.getCodeScanner());
      setIsAutoComplete(!!config.ops?.search);
      binder.value.subscribe((v: string) => {
        if (config.ops?.search) {
          if (v.length > 0 || v === "") setValue(v);
        } else {
          const currentPosition = inputRef?.current?.input.selectionStart;
          setValue(v);
          if (config.ops?.textField?.toUppercase) {
            setTimeout(() => {
              inputRef?.current?.input.setSelectionRange(
                currentPosition,
                currentPosition
              );
            }, 0);
          }
        }
      });
      binder.codeScanner.subscribe((cs: ICodeScanner) => {
        setCodeScanner(clone(cs));
      });
      // binder.searchResults.subscribe((searchResults: any)=> {
      //   console.log("*****searchResults", searchResults);
      // });
      gizmo.errors.subscribe((errors: IError[]) => {
        const searchError = errors.find(
          (e: IError) => e.code === "search.status"
        );
        if (searchError) {
          // This was added because for some reason when there was an error the autcomplete
          // did not close
          setOpen(false);
        }
      });
      gizmo.gizmoSearchFeatures.subscribe((gsf: any) => {
        setLoading(gsf.processing);
        if (!gsf.processing) {
          setOpen(true);
          setOptions(gsf.results);

          if (gsf.message) {
            const message: ISearchMessage = {
              message: gsf.message,
              type: gsf.messageType,
            };
            setSearchMessage(message);
          } else {
            setSearchMessage(undefined);
          }
          setGizmoSearchFeatures(gsf);
        }
      });

      const initiValue = binder.getValue();
      if (initiValue) {
        setValue(initiValue);
      }
    }

    let subscription: any | undefined;
    if (config.ops?.textField?.codeScannerEnabled) {
      scannerChange.current = new BehaviorSubject<any>("");
      const scannerChange$ = scannerChange.current.asObservable();
      subscription = scannerChange$
        .pipe(debounceTime(500))
        .subscribe(async (value) => {
          if (value) {
            binder.setCodeScannerResult(value);
          }
        });
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
      // binder?.value.unsubscribe();
      // binder?.codeScanner.unsubscribe();
    };
  }, [binder]);

  const handleChange = (value: string) => {
    // console.log("handleChange", value);
    if (config.ops?.textField?.keyboard === "numeric") {
      const reg = /^-?\d*(\.\d*)?$/;

      if (reg.test(value) || value === "" || value === "-") {
        binder?.setValue(value);
      }
    } else {
      binder?.setValue(value);
    }
  };

  const handlePressEnter = async () => {
    await binder?.sendSpecialKey("enter");
  };

  const handleQrButtonPress = () => {
    binder?.toggleCodeScanner();
  };

  const handleResultQrReader = (result: any) => {
    // console.log("handleResultQrReader", result);
    if (result && result.text) {
      // scannerChange.current?.next(result.text);
      binder?.setValueWithDebounce(result.text);
      // We're going to toggle in order to close the scanner
      binder?.toggleCodeScanner();
      // scannerChange.next(result.text);
    }
  };

  let type = "text";
  if (config.ops?.textField?.password?.isPassword) {
    type = "password";
  }

  /**
   * Function to handle the selection of a search result
   *
   * @param {string} value value of the selected search result
   */
  const onSelect = async (value: string) => {
    setOpen(false);
    autocompleteRef.current.blur();
    // First we ask the gizmo the search results
    const gsf = gizmo.getGizmoSearchFeatures();

    if (gsf && gsf.results && gsf.results.length > 0) {
      // We look for the one that matches the value
      const result = gsf.results.find((r: any) => r.value === value);

      if (result) {
        // And we send it to the gizmo
        await gizmo.selectGizmoSearchResult(result);
      }
    }
  };

  const onSearch = (value: string) => {
    binder?.setValueWithDebounce(value);
  };

  return (
    <GizmoWrapper features={features} errors={errors}>
      {!features.editable ? (
        <span className="notranslate">{value}</span>
      ) : !isAutoComplete ? (
        <S.TextField
          ref={inputRef}
          type={type}
          aria-label={`input-${config.fid}`}
          value={value || ""}
          onChange={(e) => handleChange(e.target.value)}
          onPressEnter={handlePressEnter}
        />
      ) : (
        <>
          <AutoComplete
            value={value || ""}
            options={options}
            onSelect={onSelect}
            onSearch={onSearch}
            onBlur={() => setOpen(false)}
            onFocus={() => setOpen(true)}
            open={open}
            ref={(ref: any) => {
              autocompleteRef.current = ref;
            }}
          />
          {loading && (
            <div style={{ textAlign: "center" }}>
              <Spin />
            </div>
          )}
          {codeScanner && features.editable && (
            <>
              <Space>
                <Button icon={<QrcodeOutlined />} onClick={handleQrButtonPress}>
                  {codeScanner.label}
                </Button>
              </Space>
            </>
          )}
        </>
      )}
      {searchMessage && (
        <>
          <br />
          <Text type={searchMessage.type}>{searchMessage.message}</Text>
        </>
      )}
      {codeScanner?.show && (
        <QrReader
          videoContainerStyle={{ paddingTop: 10 }}
          videoStyle={{
            maxWidth: 500,
            maxHeight: 500,
            position: "relative",
            display: "initial",
          }}
          onResult={handleResultQrReader}
          constraints={{
            facingMode: "environment",
          }}
        />
      )}
      {errors && errors.length > 0 && gizmoSearchFeatures && (
        <SearchResultAssociations
          errors={errors}
          gizmoSearchFeatures={gizmoSearchFeatures}
        />
      )}
    </GizmoWrapper>
  );
};

export default TextField;
