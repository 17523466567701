import { Row } from "antd";
import styled from "styled-components";

const SignatureField = styled.canvas`
  width: 100%;
  height: 200px;
`;

const ContainerImage = styled(Row)`
  border: 1px dotted #222;
  padding: 10px;
  border-radius: 15px;
`

const ContainerSignature = styled.div`
  width: 100%;
  height: 200px;
  border: 1px dotted #222;
  border-radius: 15px;
`

export {
  SignatureField,
  ContainerImage,
  ContainerSignature
};
