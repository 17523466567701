import { FC, useCallback, useMemo, useRef, useState, useEffect } from "react";
import { Gizmo, useGizmo } from "flowy-3-core";
import { Button, Divider, Image, message, Typography, Space } from "antd";
import {
  CameraOutlined,
  CloseOutlined,
  PlusOutlined,
  QrcodeOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import type { UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import { QrReader } from "react-qr-reader";
import Webcam from "react-webcam";
import { BehaviorSubject, debounceTime } from "rxjs";
import { getFileFromBase64 } from "../../utils/functions/getFile";
import transformFiles from "../FileField/transformFiles";
import { GizmoWrapper, PreviewModal } from "../../utils";
import { curpQrGuide } from "../../../../assets/images";
import * as S from "./MultimediaField.styles";

type MultimediaFieldProps = {
  gizmo: Gizmo;
  allowedTypes?: string;
  maxCount?: number;
};

const { Text } = Typography;
const scannerChange = new BehaviorSubject<any>("");
const scannerChange$ = scannerChange.asObservable();
interface ICodeScanner {
  show: boolean;
  label: string;
}

const validateFileType = ({ type }: UploadFile, allowedTypes?: string) => {
  if (!allowedTypes) return true;

  if (type) return allowedTypes.includes(type);
};

const MultimediaField: FC<MultimediaFieldProps> = ({
  gizmo,
  // TODO: get the max count from the gizmo configuration
  maxCount = 1,
  // allowedTypes = "image/jpeg,image/png,",
  // TODO: handle png images
  allowedTypes = "image/jpeg,image/png,application/pdf",
}) => {
  const { config, binder, features, errors } = useGizmo({ gizmo });

  const [fileList, setFileList] = useState<any>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewFile, setPreviewFile] = useState<{ type: string; file: any }>({
    type: "",
    file: "",
  });
  const [img, setImg] = useState<string | null>();
  const [activeCamera, setActiveCamera] = useState(false);
  const [activeQrReader, setActiveQrReader] = useState(false);
  const [qrData, setQrData] = useState<any | undefined>("");
  const [codeScanner, setCodeScanner] = useState<ICodeScanner>(
    binder.getCodeScanner()
  );
  const [coreMesage, setCoreMessage] = useState<string>("");
  const [codeHelpVisible, setCodeHelpVisible] = useState(false);
  const webcamRef = useRef<Webcam>(null);

  useEffect(() => {
    if (binder) {
      const files = binder.getFiles();

      if (files.length > 0) transformFiles(files).then((f) => setFileList(f));

      binder.files.subscribe(async (files: File[]) => {
        setFileList(await transformFiles(files));
      });
      binder.codeScanner.subscribe((codeScanner: any) => {
        setCodeScanner(codeScanner);
      });
      binder.message.subscribe((message: string) => {
        setCoreMessage(message);
      });
    }
  }, [binder]);

  useEffect(() => {
    const subscription = scannerChange$
      .pipe(debounceTime(500))
      .subscribe(async (value) => {
        await binder.setCodeScannerResult(value.text);
        setQrData(value);
        setActiveQrReader(false);
      });

    return () => {
      return subscription.unsubscribe();
    };
  }, [binder]);

  const uploadProps = useMemo(
    () =>
      ({
        multiple: true,
        beforeUpload: async (file: UploadFile) => {
          const isAllowedType = validateFileType(file, allowedTypes);
          if (!isAllowedType) {
            //! What is the purpose of this?
            setFileList((state: UploadFile[]) => [...state]);
            message.error(`${file.name} no es un archvio compatible!`);
            return true;
          }

          await binder.setFiles([file]);

          return false;
        },
        onRemove: (file: UploadFile) => {
          setFileList((prev: UploadFile[]) =>
            prev.filter((item) => item?.uid !== file.uid)
          );
        },
        onPreview: async (file: UploadFile) => {
          const fileToPreview = binder
            .getFiles()
            .find((f: File) => f.name === file.name);

          if (fileToPreview.type.includes("image")) {
            const url = URL.createObjectURL(fileToPreview);
            setPreviewFile({ type: fileToPreview.type, file: url });
          } else if (fileToPreview.type === "application/pdf") {
            setPreviewFile({ type: fileToPreview.type, file: fileToPreview });
          }
          setPreviewOpen(true);
        },
        accept: allowedTypes,
        showUploadList: {
          showRemoveIcon: features.editable,
        },
      } as UploadProps),
    [fileList]
  );

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot();
    setImg(imageSrc);
  }, [webcamRef]);

  const addCapturedImg = async () => {
    if (img) {
      const filename = `camera-${(new Date()).getTime()}.jpeg`;
      const file = await getFileFromBase64(img, filename, "image/jpeg");
      await binder.setFiles([file], "camera");
    }
  };

  const handleCaptureImg = async () => {
    setActiveCamera(true);
    setActiveQrReader(false);
  };

  const handleQrReader = async () => {
    setActiveCamera(false);
    setActiveQrReader(true);
  };

  const handleCancelQrReader = () => {
    setActiveQrReader(false);
    setQrData(undefined);
  };

  const handleResultQrReader = (result: any, error: any) => {
    if (!!result)
      // setQrData(result)
      scannerChange.next(result);

    // if (!!error)
    // console.info(error)
  };

  const showFileControl =
    fileList.length < maxCount &&
    features.editable &&
    (config.ops?.multimediaField?.inputSource === "local_file" ||
      config.ops?.multimediaField?.inputSource === "both");
  const showCameraControl =
    fileList.length < maxCount &&
    features.editable &&
    (config.ops?.multimediaField?.inputSource === "camera" ||
      config.ops?.multimediaField?.inputSource === "both");

  return (
    <GizmoWrapper features={features} errors={errors}>
      <PreviewModal
        open={previewOpen}
        setOpen={setPreviewOpen}
        previewFile={previewFile}
      />
      {fileList.length === 0 && !features.editable ? (
        <>
          <i>No se cargaron archivos</i>
          <br />
          <br />
        </>
      ) : (
        <S.Container>
          <S.MultimediaField
            id={`multimedia_field-${config.fid}`}
            listType="picture-card"
            fileList={fileList}
            maxCount={maxCount}
            disabled={!features.editable}
            {...uploadProps}
          >
            {showFileControl && (
              <div>
                <PlusOutlined />
                <div>Cargar</div>
              </div>
            )}
          </S.MultimediaField>
          {showCameraControl && (
            <>
              {!activeCamera ? (
                <>
                  <Button icon={<CameraOutlined />} onClick={handleCaptureImg}>
                    Tomar fotografía
                  </Button>
                  <Divider type="vertical" />
                </>
              ) : (
                <S.WebCamContainer>
                  {img ? (
                    <div>
                      <Image src={img} alt="screenshot" />
                      <div style={{ marginTop: 5 }}>
                        <Button onClick={() => setImg(null)}>
                          Tomar de nuevo
                        </Button>
                        <Divider type="vertical" />
                        <Button onClick={addCapturedImg}>Aceptar</Button>
                        <Divider type="vertical" />
                        <Button
                          icon={<CloseOutlined />}
                          danger
                          onClick={() => setActiveCamera(false)}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <S.WebCam
                        audio={false}
                        mirrored={true}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={{
                          facingMode: 'environment',
                        }}
                      />
                      <div>
                        <Button onClick={capture}>Tomar foto</Button>
                        <Divider type="vertical" />
                        <Button
                          icon={<CloseOutlined />}
                          danger
                          onClick={() => setActiveCamera(false)}
                        />
                      </div>
                    </div>
                  )}
                </S.WebCamContainer>
              )}
            </>
          )}
          {!activeQrReader ? (
            codeScanner.show &&
            features.editable && (
              <>
                <Space>
                  <Button icon={<QrcodeOutlined />} onClick={handleQrReader}>
                    Leer QR
                  </Button>
                  <Button
                    icon={<QuestionCircleOutlined />}
                    onClick={() => setCodeHelpVisible(true)}
                  />
                  <Image
                    src={curpQrGuide}
                    style={{ display: "none" }}
                    preview={{
                      visible: codeHelpVisible,
                      src: curpQrGuide,
                      onVisibleChange: (visible) => setCodeHelpVisible(visible),
                    }}
                  />
                </Space>
              </>
            )
          ) : (
            <S.CenterContainer>
              <QrReader
                videoContainerStyle={{ paddingTop: 10 }}
                videoStyle={{
                  maxWidth: 500,
                  maxHeight: 500,
                  position: "relative",
                  display: "initial",
                }}
                onResult={handleResultQrReader}
                constraints={{
                  facingMode: "environment",
                }}
              />
              <Text strong>{qrData?.text}</Text>
              <S.CenterContainer>
                {qrData && (
                  <Button onClick={() => setActiveQrReader(false)}>
                    Aceptar
                  </Button>
                )}
                <Divider type="vertical" />
                <Button danger onClick={handleCancelQrReader}>
                  Cancelar
                </Button>
              </S.CenterContainer>
            </S.CenterContainer>
          )}
          {message && <div>{coreMesage}</div>}
        </S.Container>
      )}
    </GizmoWrapper>
  );
};

export default MultimediaField;
